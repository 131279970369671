<style lang="less" scoped>
.count-page {
  width: 100%;
  height: calc(100% - 16px);
  padding: 16px 16px 0;
  display: flex;
  flex-direction: column;
  .top {
    flex: 500;
    margin-bottom: 14px;
  }
  .bottom {
    flex: 400;
    display: flex;
    .left, .right {
      flex: 1;
    }
    .left { margin-right: 10px; }
    .right { margin-left: 10px; }
  }
}
</style>

<template>
  <div class="count-page" v-loading="loading">
    <div class="top">
      <!-- <Top :age-data="fData('ageMap')" :edu-data="fData('eduMap')" :job-data="fData('jobSexMap')"></Top> -->
      <Top @jobTypeChange="jobTypeChange" :eduList="['中专', '大专', '本科', '硕士研究生', '博士研究生']" :jobList="['员级', '助理级', '中级', '副高级', '正高级']" :age-data="fData('ageMap')" :edu-data="fData('eduMap')" :job-data="fData('jobSexMap')"></Top>
    </div>
    <div class="bottom">
      <div class="left">
        <BottomLeft ref="rate" :sex-data="countData.sexMap" :yh-data="countData.yhMap" :yhj-data="countData.yhjMap" :job-data="countData.jobMap" :pol-data="countData.polMap" :type-data="countData.typeMap"/>
      </div>
      <div class="right">
        <BottomRight :entry-data="entryDataDate"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  workerRequest,
  analysisRequest,
  workerEduRequest
} from '../../api'

import {
  dateOperating
} from '@/fmlib'

import {
  getAge
} from '@/syslib/tools'

import Top from './count/top'
import BottomRight from './count/nums'
import BottomLeft from './count/rate'

var timer = null

export default {
  components: { Top, BottomRight, BottomLeft },
  data () {
    return {
      loading: false,
      entryDataDate: [],
      jobType: 'all',
      countData: {
        sexMap: {},
        yhjMap: {},
        yhMap: {},
        jobMap: {},
        polMap: {},
        typeMap: {}
      }
    }
  },
  created () {
    if (this.$authFunsProxy.base) {
      this.loadData()
    }
  },
  computed: {
    filters () {
      if (this.$route.query && Object.keys(this.$route.query).length) {
        if (this.$route.query.orgTreeCode) {
          return (item) => {
            return item.userOrgRoleList.filter(v => v.orgTreeCode && v.orgTreeCode.startsWith(this.$route.query.orgTreeCode)).length > 0
          }
        } else if (this.$route.query.orgId && this.$route.query.roleId) {
          return (item) => {
            return item.userOrgRoleList.filter(v => v.orgId === this.$route.query.orgId && v.roleId === this.$route.query.roleId).length > 0
          }
        }
      } else {
        return null
      }
      return null
    }
  },
  watch: {
    filters () {
      this.loadData()
    }
  },
  methods: {
    jobTypeChange (value) {
      this.jobType = value
      this.loadData()
    },
    fData (key) {
      return this.entryDataDate.map((v) => {
        return {
          month: v.month,
          data: v[key]
        }
      })
    },
    dealEntryDataDate (data) {
      let yearDatas = []
      let lastYear = null
      data.forEach((v) => {
        let cYear = v.month.slice(0, 4)
        if (!lastYear) {
          lastYear = cYear
          yearDatas.push(Object.assign({}, v, {month: lastYear}))
        } else if (lastYear === cYear) {
          yearDatas[yearDatas.length - 1] = Object.assign({}, v, {month: lastYear})
        } else {
          while (Number(lastYear) < Number(cYear)) {
            lastYear = String(Number(lastYear) + 1)
            yearDatas.push(Object.assign({}, yearDatas[yearDatas.length - 1], {month: lastYear}))
          }
          yearDatas[yearDatas.length - 1] = Object.assign({}, v, {month: lastYear})
          lastYear = cYear
        }
      })
      return yearDatas
    },
    dealTimeData (datas) {
      let timeDatas = datas.filter(v => v.entryDate && ['男', '女'].includes(v.sex))
      timeDatas.sort((a, b) => {
        let d1 = a.entryDate ? dateOperating.computeDay({days: 0, date: a.entryDate, format: 'stamps'}) : 0
        let d2 = b.entryDate ? dateOperating.computeDay({days: 0, date: b.entryDate, format: 'stamps'}) : 0
        return d1 - d2
      })
      let entryData = []
      let entryDataMap = {}
      let entryDataDate = []
      if (timeDatas.length > 0) {
        let monthf = timeDatas[0].entryDate.slice(0, 7)
        entryData = [{
          month: monthf,
          num: 1
        }]
        entryDataMap[monthf] = [timeDatas[0]]
        timeDatas.slice(1).forEach((v) => {
          let month = v.entryDate.slice(0, 7)
          let lastMonth = entryData[entryData.length - 1].month
          if (month === lastMonth) {
            entryData[entryData.length - 1].num += 1
            entryDataMap[month].push(v)
          } else {
            entryData.push({
              month: month,
              num: entryData[entryData.length - 1].num + 1
            })
            entryDataMap[month] = entryDataMap[lastMonth].map(v => v)
            entryDataMap[month].push(v)
          }
        })
      }
      let ages = ['0', '20', '30', '40', '50', '60']
      let edus = ['中专', '大专', '本科', '硕士研究生', '博士研究生']
      let jobLevels = ['员级', '助理级', '中级', '副高级', '正高级']
      // let jobLevels = ['无职称', '员级', '助理级', '中级', '副高级', '正高级']
      Object.keys(entryDataMap).forEach((month) => {
        let dataItem = {
          month: month,
          num: entryDataMap[month].length,
          ageMap: {},
          eduMap: {},
          jobSexMap: {}
        }
        ages.forEach(v1 => {
          dataItem.ageMap[v1] = {
            'm': 0,
            'w': 0
          }
        })
        edus.forEach(v => {
          dataItem.eduMap[v] = {
            'm': 0,
            'w': 0
          }
        })
        jobLevels.forEach(v => {
          dataItem.jobSexMap[v] = {
            'm': 0,
            'w': 0
          }
        })
        entryDataMap[month].forEach((v) => {
          let sex = v.sex === '男' ? 'm' : 'w'
          let age = null
          if (v.birth) {
            age = getAge(v.birth, new Date())
            if (age < 20) {
              dataItem.ageMap['0'][sex] += 1
            } else if (age < 30) {
              dataItem.ageMap['20'][sex] += 1
            } else if (age < 40) {
              dataItem.ageMap['30'][sex] += 1
            } else if (age < 50) {
              dataItem.ageMap['40'][sex] += 1
            } else if (age < 60) {
              dataItem.ageMap['50'][sex] += 1
            } else {
              dataItem.ageMap['60'][sex] += 1
            }
          }
          if (v.education && dataItem.eduMap[v.education]) {
            dataItem.eduMap[v.education][sex] += 1
          }
          if (v.jobLevel) {
            dataItem.jobSexMap[v.jobLevel][sex] += 1
          // } else {
          //   dataItem.jobSexMap['无职称'][sex] += 1
          }
          // if (v.jobTitle) {
          //   if (v.jobTitle.includes('副')) {
          //     dataItem.jobSexMap['副高级职称'][sex] += 1
          //   } else if (v.jobTitle.includes('主任')) {
          //     dataItem.jobSexMap['高级职称'][sex] += 1
          //   } else if (v.jobTitle.includes('主管')) {
          //     dataItem.jobSexMap['中级职称'][sex] += 1
          //   } else {
          //     dataItem.jobSexMap['初级职称'][sex] += 1
          //   }
          // } else {
          //   dataItem.jobSexMap['无职称'][sex] += 1
          // }
        })
        entryDataDate.push(dataItem)
      })
      this.entryDataDate = this.dealEntryDataDate(entryDataDate)
    },
    async getData () {
      let wjt = await analysisRequest.certificateLevel()
      let eduDatas = await workerEduRequest.get()
      let eduMap = {}
      eduDatas.filter(v => v.type && v.type.includes('最高学历')).forEach(v => eduMap[v.workerId] = eduMap[v.workerId] ? eduMap[v.workerId].concat(v) : [v])
      let datas = []
      if (this.$authFunsProxy.get) {
        datas = await workerRequest.get()
      } else if (this.$authFunsProxy.getMyOrgContainChild) {
        datas = workerRequest.getMyOrgContainChild()
      } else if (this.$authFunsProxy.getMyOrg) {
        datas = await workerRequest.getMyOrg()
      }
      datas = datas.filter(v => !this.filters || this.filters(v))
      // 离职的不统计
      datas = datas.filter(v => !v.discipline)
      if (this.jobType && this.jobType !== 'all') {
        datas = datas.filter(v => v.jobType === this.jobType)
      }
      datas.forEach((v) => {
        v.education = eduMap[v.id] && eduMap[v.id].length > 0 ? eduMap[v.id][0].edu : null
        let lWjt = wjt.find(v1 => v1.id === v.id)
        v.jobLevel = lWjt ? lWjt.level : null
        v.jobTitle2 = v.jobTitle
        v.jobTitle = lWjt ? lWjt.yh : null
      })
      return datas
    },
    async loadData () {
      this.loading = true
      clearTimeout(timer)
      let datas = await this.getData()
      this.dealTimeData(datas)
      this.loading = false
      let sexMap = {
        'm': 0,
        'w': 0
      }
      let yhMap = {
        '医生': 0,
        '护士': 0,
      }
      let yhjMap = {
        '医技': 0,
        '其他': 0
      }
      // let jobLevels = ['无职称', '员级', '助理级', '副高级', '正高级']
      let jobLevels = ['员级', '助理级', '中级', '副高级', '正高级']
      let jobMap = {}
      jobLevels.forEach(v => {
        jobMap[v] = 0
      })
      let polMap = {}
      let pils = ['中共党员', '中共预备党员', '共青团员', '其他']
      pils.forEach(v => {
        polMap[v] = 0
      })
      let typeMap = {}
      let types = ['在编', '非在编']
      types.forEach(v => {
        typeMap[v] = 0
      })
      datas.forEach((v) => {
        let sex = v.sex === '男' ? 'm' : (v.sex === '女' ? 'w' : null)
        if (v.jobTitle && v.jobTitle.includes('医')) {
          yhMap['医生'] += 1
        } else if (v.jobTitle && v.jobTitle.includes('护')) {
          yhMap['护士'] += 1
        }
        if (v.jobTitle2 === '是') {
          yhjMap['医技'] += 1
        } else {
          yhjMap['其他'] += 1
        }
        if (v.jobLevel) {
          jobMap[v.jobLevel] += 1
        // } else {
        //   jobMap['无职称'] += 1
        }
        if (v.politicalStatus && Object.keys(polMap).includes(v.politicalStatus)) {
          polMap[v.politicalStatus] += 1
        } else {
          polMap['其他'] += 1
        }
        if (v.type && v.type === '在编') {
          typeMap['在编'] += 1
        } else {
          typeMap['非在编'] += 1
        }
        if (sex) {
          sexMap[sex] += 1
        }
      })
      let countData = {
        sexMap,
        yhjMap,
        yhMap,
        jobMap,
        polMap,
        typeMap
      }
      this.countData = countData
      timer = setTimeout(this.loadData, 180000)
    }
  },
  beforeRouteEnter: function (to, from, next) {
    next(vm => vm.$nextTick(vm.$refs.rate.start))
  },
  beforeRouteLeave: function (to, from, next) {
    next(vm => vm.$nextTick(vm.this.$refs.rate.stop))
  },
  destroyed () {
    clearTimeout(timer)
  }
}
</script>
