<style scoped lang="less">
  .card {
    background-color: #FFF;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 100%;
    flex: 1;
  }
  .echart {
    width: 50%;
    height: 50%;
    z-index: 0;
  }
</style>

<template>
  <Card class="card-c" title="比例仪表盘" style="display: flex;height: 100%;flex-direction: column;">
    <div class="card">
      <div ref="c1" class="echart"></div>
      <div ref="c2" class="echart"></div>
      <div ref="c3" class="echart"></div>
      <div ref="c4" class="echart"></div>
    </div>
  </Card>
</template>

<script>
import Card from './card'
const echarts = require('echarts')

var timer = null
var plays = []
var ready = new Array(4).fill(null).map(() => {
  let r = null
  let p = new Promise(resolve => {
    r = resolve
  })
  return {
    promise: p, resolve: r
  }
})

export default {
  components: { Card },
  props: {
    yhData: {
      type: Object, default: () => ({})
    },
    yhjData: {
      type: Object, default: () => ({})
    },
    jobData: {
      type: Object, default: () => ({})
    },
    typeData: {
      type: Object, default: () => ({})
    },
  },
  data () {
    return {
      refs: {},
      playIndex: 0,
      plays: [
        ['c2', 'c3'],
        ['c1', 'c4']
      ]
    }
  },
  watch: {
    yhData: {
      deep: true,
      immediate: true,
      handler (v) {
        v && this.draw1()
      }
    },
    yhjData: {
      deep: true,
      immediate: true,
      handler (v) {
        v && this.draw2()
      }
    },
    jobData: {
      deep: true,
      immediate: true,
      handler (v) {
        v && this.draw3()
      }
    },
    typeData: {
      deep: true,
      immediate: true,
      handler (v) {
        v && this.draw4()
      }
    }
  },
  methods: {
    goplay () {
      clearTimeout(timer)
      if (plays.length) {
        plays.forEach(ref => {
          let echart = this.refs[ref]
          if (echart && !echart.stopAutoPlay) {
            let option = echart.getOption()

            if (echart.autoPlayDataIndex !== undefined) {
              echart.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: echart.autoPlayDataIndex
              })
              if (option.series.length >= 1 && option.series[0].data.length > 1) {
                echart.autoPlayDataIndex = ++echart.autoPlayDataIndex % option.series[0].data.length
              } else {
                delete echart.autoPlayDataIndex
                plays.indexOf(ref) > -1 && plays.splice(plays.indexOf(ref), 1)
              }
            } else {
              echart.autoPlayDataIndex = 0
            }
            
            if (echart.autoPlayDataIndex !== undefined) {
              echart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: echart.autoPlayDataIndex
              })
              echart.dispatchAction({
                type: 'showTip',
                seriesIndex: 0,
                dataIndex: echart.autoPlayDataIndex
              })
            }
          }
        })
        timer = setTimeout(this.goplay, 3000)
      }
    },
    getOption ({title, data}, position) {
      return {
        title: {
          text: title,
          top: 'center',
          left: 'center',
          color: 'rgba(0, 0, 0, 0.85)',
          textStyle: { fontSize: 14, fontWeight: 500 }
        },
        tooltip: {
          position: position,
          trigger: 'item', formatter: function (param) {
            return param.marker + ' '+ param.name + ' ' + param.percent + '%'
          }
        },
        grid: { left: '3%', right: '4%', bottom: '4%', top: '0', containLabel: true },
        legend: { show: false },
        color: ['#FB6D8F', '#A0DBE3', '#F5E2DF', '#FFCFD7', '#FF9EA3'],
        series: [
          {
            name: title,
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: { show: false, position: 'center' },
            emphasis: { label: { show: false } },
            labelLine: { show: false },
            data: data
          }
        ]
      }
    },
    clearAutoplay (ref) {
      const index = plays.indexOf(ref)
      index > -1 && plays.splice(index, 1)
      const echart = this.refs[ref]
      echart && echart.off('mouseover').off('globalout')
      if (echart && echart.autoPlayDataIndex !== undefined && !echart.stopAutoPlay) {
        echart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: echart.autoPlayDataIndex
        })
        echart.dispatchAction({
          type: 'hideTip',
          seriesIndex: 0,
          dataIndex: echart.autoPlayDataIndex
        })
      }
    },
    setAutoplay (ref) {
      if (!plays.includes(ref)) {
        plays.push(ref)
        this.refs[ref].off('mouseover').on('mouseover', () => {
          this.refs[ref].stopAutoPlay = true
          if (this.refs[ref].autoPlayDataIndex !== undefined) {
            this.refs[ref].dispatchAction({
              type: 'downplay',
              seriesIndex: 0,
              dataIndex: this.refs[ref].autoPlayDataIndex
            })
          }
        }).off('globalout').on('globalout', () => {
            this.refs[ref].stopAutoPlay = false
        })
      }
    },
    draw (ref, option) {
      if (this.$refs[ref]) {
        if (!this.refs[ref]) {
          this.refs[ref] = echarts.init(this.$refs[ref])
          this.refs[ref].ref = ref
        }
        this.refs[ref].setOption(option, true)
        ready[Object.keys(this.refs).length - 1].resolve()
      }
    },
    draw1 () {
      this.draw('c1', this.getOption({
        title: '职称\n占比',
        data: Object.keys(this.jobData).map((v) => {
          return {
            name: v,
            value: this.jobData[v]
          }
        })
      }, [10, 10]), {autoplay: true})
    },
    draw2 () {
      this.draw('c2', this.getOption({
        title: '在编人员\n占比',
        data: Object.keys(this.typeData).map((v) => {
          return {
            name: v,
            value: this.typeData[v]
          }
        })
      }, ['70%', 10]))
    },
    draw3 () {
      this.draw('c3', this.getOption({
        title: '卫生专技\n占比',
        data: [{name: '医技', value: this.yhjData['医技']}, {name: '其他', value: this.yhjData['其他']}]
      }, [10, 10]))
    },
    draw4 () {
      this.draw('c4', this.getOption({
        title: '医护\n比例',
        data: [{name: '医生', value: this.yhData['医生']}, {name: '护士', value: this.yhData['护士']}]
      }, ['70%', 10]), {autoplay: true})
    },
    groupPlay () {
      this.plays[this.playIndex].forEach(i => this.clearAutoplay(i))
      this.playIndex = ++this.playIndex % this.plays.length
      this.plays[this.playIndex].forEach(i => this.setAutoplay(i))
      this.goplay()
      setTimeout(this.groupPlay, 10000)
    },
    stop () {
      clearTimeout(timer) 
    },
    async start () {
      await Promise.all(ready.map(v => v.promise))
      this.groupPlay()
    }
  }
}
</script>
